<template>
   <div class="app-container" style="margin-bottom: 20px">
    <!-- -------万恶分割线------- -->
    <!-- 添加或编辑类别 -->
  
    <div style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="left">
          <el-button class="el-icon-edit" type="primary" @click="goback"
            >返回</el-button
          >
        </el-col>
       
      </el-row>
    </div>
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="120px"
      
      >
     <el-row  >
        <el-form-item  
        label="上传图片" 
         v-model="formData.fileList"
              prop="fileList"
              :rules="rules.fid">
          <el-upload
            :file-list="fileList"
            multiple
          list-type="picture-card"
            action="http://up-z0.qiniup.com"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
             :on-remove="handleRemove"
          >
          <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="imageUrl" alt="" />
          </el-dialog>
        </el-form-item>
     </el-row>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            提交
          </el-button>
        </el-col>
      </el-row>
 
  </div>
</template>

<script>
    import {
  addAlbumFile,
  getAlbumFileList,
  delAlbumFile,
  getUploadToken
} from '../../request/http'
export default {
    data() {
    return {
      editOradd:null,
      imgList: [], //多张照片回显
      fileList: [],
      list: '', //列表
      total: 0, //总数
      imageUrl: '',
      img: '',
      dialogVisible: false,
      postData: {
        token: '',
        key: '' //上传的Logo
      },

      postData0: {
        token: '',
        key: ''
      },
      postData1: {
        token: '',
        key: '' //上传的Logo
      },
      albumId: '',
      queryParams: {
        page: 1,
        pageSize: 10
      },
      title: '',
      showDialog: false,
    
      fileType:null,
      formData: {
        albumId: '',
        fileList: [
        ]
      },
      rules: {
        // posterId: [
        //   { required: true, message: "封面不能为空", trigger: "blur" },
        // ],
        fileType: [
          { required: true, message: '上传类型不能为空', trigger: 'blur' }
        ],
        fid: [{ required: true, message: '文件不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.albumId = this.$route.query.albumId
    this.formData.albumId = this.$route.query.albumId
    console.log(this.formData.albumId)

    for (let i = 0; i < this.formData.fileList.length; i++) {
        this.fileList.push({
          url: "http://file.innopinenut.com/" +  this.formData.fileList[i].fid,
          key: this.formData.fileList[i].fid,
        });
      }
  },
 

  methods: {
    // 上传图片方法
    handleAvatarSuccess(res) {
         this.formData.fileList.push({
            fileType:1,
            fid:res.key
        })

        console.log(this.formData.fileList);
      
    },
    async beforeAvatarUpload(file) {
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传时获取Token失败！')
        return
      }
      this.postData.token = data.token
      this.postData.key = data.fid
    },
 

      //删除多张图片的一张
      handleRemove(file) {
      let key;
      if (this.editOradd) {
        key = file.key;
      } else {
        key = file.response.key;
      }
       
      let i = this.formData.fileList.indexOf(key);
      console.log(key,i);
      // console.log(i);
      this.formData.fileList= this.formData.fileList.filter(item => item.fid!==key)
      console.log(this.formData.fileList);
    
    },
    //点击放大，查看图片
    handlePictureCardPreview(file) {
    //   console.log(file);
    //   window.open(file.url, '_blank')
      this.imageUrl = file.url;
      this.dialogVisible = true;
    },
  
 
    // 返回上一级页面
    goback() {
      this.$router.go(-1)
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
        let { code, msg } = await addAlbumFile(this.formData)
        console.log(code, msg)
        if (code == 0) {
          this.$message.success('新增成功')
        } else {
          console.log(msg)
          this.$message({
            type: 'error',
            message: msg
          })
        }
        this.goback()
      })
    },

   
  
  }
}

</script>

<style>

</style>